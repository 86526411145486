import { css } from 'styled-components';
import { device } from '@stockbit/ui';

const layoutPageContainerStyles = css`
  .chat-page-layout-container {
    padding-top: 60px !important;
    margin-top: unset !important;
    padding-right: 0px !important;

    @media ${device.tablet} {
      padding-top: 120px !important;
    }

    @media ${device.laptop} {
      max-width: 1300px;
    }
  }
`;

export default layoutPageContainerStyles;
