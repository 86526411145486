import requestConfig from 'config/requestExodus';
import { API_EXODUS_URL } from 'constants/api';
import { APIResponse } from '../../../@types/api-response';

const request = requestConfig();

const SECTOR_API = `${API_EXODUS_URL}/emitten`;

const getSectorList = (): Promise<APIResponse<Array<any>>> =>
  request.get(`${SECTOR_API}/sectors`);

const getSubsectorList = (sectorId: string): Promise<APIResponse<Array<any>>> =>
  request.get(`${SECTOR_API}/sectors/${sectorId}/subsectors`);

const getCompanyList = (
  sectorId: string,
  subSectorId: string,
): Promise<APIResponse<Array<any>>> =>
  request.get(`${SECTOR_API}/v3/sector/${sectorId}/subsector/${subSectorId}/company`);

const getCompanyInSector = (sectorId: string): Promise<APIResponse<Array<any>>> =>
  request.get(`${SECTOR_API}/v3/sector/${sectorId}/company`);

export default {
  getSectorList,
  getSubsectorList,
  getCompanyList,
  getCompanyInSector,
};
