import {
  ActionReducerMapBuilder,
  AsyncThunk,
  CaseReducer,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import metric from 'lib/api/metric';
import watchlist from 'lib/api/watchlist';
import alertMessage from 'global/AlertMessage';
import handleErrorMessageAPI from 'global/AlertErrorMessage';
import ERROR_MESSAGE from 'constants/errorMessage';
import { FinancialMetrics } from '../../../../../@types/metrics';

// utils
import rootSelector from '../../selectors';

// local types
import { FinancialMetricsState } from '../types';
// local slices
import tableSlice from '../Table/slice';

// destructure
const { actions: tableActions } = tableSlice;

// constants
const SLICE_NAME = 'financialMetrics';
const THUNK_CONTEXT = 'feature/watchlist/financialMetrics';

// initial state
const initialState: FinancialMetricsState = {
  error: null,
  isLoading: false,
  isModalOpen: false,
  list: [],
  menu: [[], [], []],
  selectedColumnId: {
    0: null,
    1: null,
  },
};

// Reducer
type SliceReducers = {
  toggleModalVisibility: CaseReducer<
    FinancialMetricsState,
    PayloadAction<{ visibility?: boolean }>
  >;
  handleMenuClick: CaseReducer<
    FinancialMetricsState,
    PayloadAction<{
      columnIndex: number;
      childs: FinancialMetrics[];
      id: string;
    }>
  >;
};

const reducers: SliceReducers = {
  toggleModalVisibility: (draft, action) => {
    const isOpen = action.payload.visibility || !draft.isModalOpen;
    draft.isModalOpen = isOpen;
  },
  handleMenuClick: (draft, action) => {
    const { columnIndex, childs, id } = action.payload;

    // update the selected column id per column index
    draft.selectedColumnId[columnIndex] = id;

    //  reset the child columns first
    draft.menu[columnIndex + 1] = [];
    if (columnIndex === 0) {
      draft.menu[columnIndex + 2] = [];
    }

    // then re-fill up the child column
    draft.menu[columnIndex + 1] = childs;
  },
};

// thunk actions
type Thunks = {
  fetchMetrics: AsyncThunk<
    {
      data: FinancialMetrics[];
    }, // return types
    {}, // payload
    {} // thunk api
  >;
  addMetrics: AsyncThunk<
    void,
    {
      fitem: string;
      watchlistid: string;
      name: string;
    },
    {}
  >;
};

export const thunks: Thunks = {
  fetchMetrics: createAsyncThunk(`${THUNK_CONTEXT}/FETCH_METRICS`, async () => {
    const response = await metric.getFinancialMetric();

    const body = response.data;

    if (body.error) {
      throw new Error(body.error);
    }

    return { data: body.data };
  }),
  addMetrics: createAsyncThunk(
    `${THUNK_CONTEXT}/ADD_METRICS`,
    async ({ fitem, watchlistid, name }, { dispatch }) => {
      try {
        const response = await watchlist.addFinancialMetrics(
          watchlistid,
          fitem,
        );

        const { data, error, message } = response.data;

        if (error) {
          throw new Error(error);
        }

        alertMessage({
          content: message,
          messageType: 'success',
          alertType: 'plain',
        });

        const result = {
          column: {
            dataIndex: data.item_id.toString(),
            title: data.item_name,
            key: data.item_id.toString(),
          },
          results: data.results,
        };

        dispatch(tableActions.addColumn(result));
      } catch (err) {
        const { data = {} } = err?.response || {};
        const message = data?.message || '';

        if (message.toLowerCase().includes('duplicate')) {
          handleErrorMessageAPI(
            `Column '${name}' already exist`,
            ERROR_MESSAGE.ALERT_RED,
          );
        }
        // return something?
      }
    },
  ),
};

// Thunk reducers
const extraReducers = (
  builder: ActionReducerMapBuilder<FinancialMetricsState>,
) => {
  builder
    // fetch financial metrics
    .addCase(thunks.fetchMetrics.pending, (draft) => {
      draft.isLoading = true;
    })
    .addCase(thunks.fetchMetrics.fulfilled, (draft, action) => {
      draft.isLoading = false;
      draft.list = action.payload.data;
      draft.menu[0] = action.payload.data;
    })
    .addCase(thunks.fetchMetrics.rejected, (draft, action) => {
      draft.isLoading = false;
      draft.error = action.error;
    })
    // add financial metrics to watchlist
    .addCase(thunks.addMetrics.pending, (draft) => {
      draft.isLoading = true;
    })
    .addCase(thunks.addMetrics.fulfilled, (draft) => {
      draft.isLoading = false;
      draft.isModalOpen = false;
    })
    .addCase(thunks.addMetrics.rejected, (draft, action) => {
      draft.isLoading = false;
      draft.error = action.error;
    });
};

// selectors
export const selectors = createSelector(rootSelector, (state) => ({
  ...state.watchlistTable.financialMetrics,
  activeWatchlistid: state.root.activeWatchlistId,
}));

export const listMetricsSelector = createSelector(
  (state) => state.watchlist.watchlistTable.financialMetrics.list,
  (state) => state,
);

const financialMetricSlice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers,
  extraReducers,
});

export default financialMetricSlice;
