import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import produce from 'immer';
import debounce from 'lodash.debounce';

import {
  WebsocketRequest,
  WebsocketResponse,
} from '@stockbitgroup/protos/platform/websocket/wsevent/v1/websocket_pb';

import securitiesLocalStorage from 'utils/securitiesLocalStorage';

import { isClient } from 'constants/app';

import { useSecuritiesStore } from './store/securities.store';
import { ChannelPayload } from './store/securities.store.types';

import { SecuritiesContext } from './SecuritiesContext';
import useUnauthorized from './hooks/useUnauthorized';

import {
  RECONNECT_INTERVAL,
  HEARTBEAT_INTERVAL,
  MAX_RECONNECT_ATTEMPTS,
} from './constants';

import { createPingPayload, generateWSCookie } from './utils';

import {
  SecuritiesContextValue,
  SecuritiesProviderProps,
  SetSecuritiesAction,
  RemoveSecuritiesAction,
} from './SecuritiesProvider.types';

const WS_URL = process.env.NEXT_PUBLIC_GEN_WEBSOCKET;

const SecuritiesProvider: FC<SecuritiesProviderProps> = (props) => {
  const {
    children,
    isLoggedIn,
    heartbeatInterval = HEARTBEAT_INTERVAL,
  } = props;

  const actionsRef = useRef({});
  const isLoggedInRef = useRef<boolean>(false);
  const lastPing = useRef<number>(Date.now());
  const lastPong = useRef<number>(Date.now());
  const reconnectCounterRef = useRef<number>(1);
  const reconnectIntervalRef = useRef<NodeJS.Timeout>();
  const socketRef = useRef<WebSocket>();

  const [authToken, setAuthToken] = useState<string>();

  const {
    channel,
    isReconnectionFailed,
    setChannel,
    setIsReconnectionFailed,
    setIsSocketOnline,
  } = useSecuritiesStore((state) => ({
    channel: state.channel,
    isReconnectionFailed: state.isReconnectionFailed,
    setChannel: state.setChannel,
    setIsReconnectionFailed: state.setIsReconnectionFailed,
    setIsSocketOnline: state.setIsSocketOnline,
  }));

  const { checkUnauthorized } = useUnauthorized();

  const setCookie = useCallback(
    (value) => {
      if (!isClient()) return;

      document.cookie = generateWSCookie(value);
    },
    [isClient()],
  );

  const setAction: SetSecuritiesAction = (channelKey, callbackFn) => {
    actionsRef.current = produce(actionsRef.current, (draft) => {
      draft[channelKey] = callbackFn;
    });
  };

  const removeAction: RemoveSecuritiesAction = (channelKey) => {
    actionsRef.current = produce(actionsRef.current, (draft) => {
      if (draft?.[channelKey]) {
        delete draft[channelKey];
      }
    });
  };

  const isWebsocketNotReady = useCallback(() => {
    const currentSocket = socketRef.current;

    if (
      (!currentSocket && currentSocket?.readyState !== WebSocket.OPEN) ||
      !currentSocket ||
      currentSocket?.readyState === WebSocket.CONNECTING
    ) {
      return true;
    }

    return false;
  }, [socketRef.current?.readyState]);

  const getSecuritiesAccessToken = useCallback(() => {
    if (isClient()) {
      const securitiesToken = securitiesLocalStorage.getSecuritiesToken();

      return { key: securitiesToken.securitiesAccessToken };
    }
    return { key: undefined };
  }, []);

  const sendPingHeartbeat = useCallback(() => {
    if (isWebsocketNotReady()) return;

    const pingRequest = createPingPayload();

    const bufferPing = new WebsocketRequest({
      requests: [pingRequest],
    }).toBinary();

    socketRef.current.send(bufferPing);

    lastPing.current = Date.now();
  }, []);

  const combinedChannel: ChannelPayload[] = useMemo(() => {
    const resultArray = Object.values(channel);

    return resultArray;
  }, [channel]);

  const sendMessageChannel = useCallback((channelRequests) => {
    if (
      isWebsocketNotReady() ||
      !socketRef.current ||
      channelRequests.length === 0
    ) {
      return;
    }

    const buffer = new WebsocketRequest({
      requests: channelRequests,
    }).toBinary();

    socketRef.current.send(buffer);
  }, []);

  const handleSendChannel: typeof sendMessageChannel = useCallback(
    debounce(sendMessageChannel, 100),
    [],
  );

  const handleMessage = useCallback((message: MessageEvent<ArrayBuffer>) => {
    const dataRaw = WebsocketResponse.fromBinary(new Uint8Array(message.data));

    const { response } = dataRaw;
    const { case: messageCase, value } = response;

    if (messageCase === 'ping') {
      lastPong.current = Date.now();
    }

    const channelAction = actionsRef.current[messageCase];
    if (channelAction) {
      channelAction(value);
    }
  }, []);

  const handleHeartbeatMessage = () => {
    setTimeout(() => sendPingHeartbeat(), heartbeatInterval);
  };

  const handleAuth = () => {
    if (!socketRef.current) return;

    removeAction('ping');
    setAction('ping', handleHeartbeatMessage);
    sendPingHeartbeat();
    setIsSocketOnline(true);

    socketRef.current.addEventListener('message', handleMessage);

    if (reconnectIntervalRef.current) {
      clearInterval(reconnectIntervalRef.current);
      reconnectIntervalRef.current = undefined;
      reconnectCounterRef.current = 1;
      setIsReconnectionFailed(false);
    }
  };

  const handleReconnectionFailed = useCallback(() => {
    if (reconnectIntervalRef.current) {
      clearInterval(reconnectIntervalRef.current);
    }

    reconnectIntervalRef.current = setInterval(() => {
      // eslint-disable-next-line no-use-before-define
      restartSocket();
    }, RECONNECT_INTERVAL);
  }, [isReconnectionFailed]);

  const handleReconnection = async () => {
    const { key } = getSecuritiesAccessToken();
    const isErrorUnauthorized = await checkUnauthorized(WS_URL, key);

    setIsSocketOnline(false);

    if (!isLoggedInRef.current || isErrorUnauthorized) return;

    if (reconnectCounterRef.current < MAX_RECONNECT_ATTEMPTS) {
      // eslint-disable-next-line no-use-before-define
      restartSocket();
    } else if (!isReconnectionFailed) {
      setIsReconnectionFailed(true);
      handleReconnectionFailed();
    }

    reconnectCounterRef.current += 1;
  };

  const initSocket = useCallback(() => {
    if (socketRef.current) return;
    setAuthToken(undefined);

    const { key } = getSecuritiesAccessToken();

    if (!key || !WS_URL) return;

    setAuthToken(key);
    setCookie(key);

    socketRef.current = new WebSocket(WS_URL);
    socketRef.current.binaryType = 'arraybuffer';
    // @ts-ignore
    window.securitiesSocket = socketRef.current;

    socketRef.current.addEventListener('open', handleAuth, { once: true });
    socketRef.current.addEventListener('close', handleReconnection, {
      once: true,
    });
  }, [handleAuth, handleReconnection]);

  const resetSocket = useCallback(() => {
    if (!socketRef.current) return;

    if (socketRef.current?.readyState === WebSocket.OPEN) {
      socketRef.current.close();
    }

    socketRef.current = undefined;

    setAuthToken(undefined);
  }, []);

  const restartSocket = useCallback(() => {
    if (!isLoggedInRef.current) return;

    resetSocket();
    initSocket();
  }, [initSocket, resetSocket]);

  const resetState = useCallback(() => {
    setIsReconnectionFailed(false);
    setAuthToken(undefined);
    setCookie(undefined);

    reconnectCounterRef.current = 1;

    if (reconnectIntervalRef.current) {
      clearInterval(reconnectIntervalRef.current);

      reconnectIntervalRef.current = undefined;
    }
  }, [
    setIsReconnectionFailed,
    setAuthToken,
    reconnectCounterRef,
    reconnectIntervalRef,
  ]);

  const handleSocket = useCallback(
    (isLogin: boolean) => {
      if (!socketRef.current && isLogin) {
        restartSocket();
      } else if (socketRef.current && !isLogin) {
        resetSocket();
      }

      if (!isLogin) {
        resetState();
      }
    },
    [isLoggedIn, initSocket, resetSocket],
  );

  useEffect(() => {
    if (authToken && combinedChannel) {
      handleSendChannel(combinedChannel);
    }
  }, [
    authToken,
    combinedChannel,
    socketRef.current?.readyState,
    handleSendChannel,
  ]);

  useEffect(() => {
    isLoggedInRef.current = isLoggedIn;

    handleSocket(isLoggedIn);
  }, [isLoggedIn]);

  const contextValue: SecuritiesContextValue = useMemo(
    () => ({
      isAuthorized: !!authToken,
      token: authToken,
      socket: socketRef.current,
      setChannel,
      setAction,
      removeAction,
    }),
    [authToken, socketRef, setChannel, setAction, removeAction],
  );

  return (
    <SecuritiesContext.Provider value={contextValue}>
      {children}
    </SecuritiesContext.Provider>
  );
};

export default SecuritiesProvider;
