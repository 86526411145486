import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

// Auth Related Modules
import authAPI from 'lib/api/auth';

// Actions
const CONTEXT = '@reset-password';

const actionType = {
  SUBMIT_NEW_PASSWORD: `${CONTEXT}/SUBMIT_NEW_PASSWORD`,
};

// initial state
const initialState = {
  error: null,
  form: {
    newpass: '',
    confirmpass: '',
    passkey: '',
    userid: '',
  },
  isLoading: false,
  isSuccess: false,
  message: '',
  data: null,
};

// Selectors
export const selectors = createSelector(
  (state) => ({
    error: state.resetPassword.error,
    isLoading: state.resetPassword.isLoading,
    isSuccess: state.resetPassword.isSuccess,
    message: state.resetPassword.message,
    data: state.resetPassword.data,
  }),
  (state) => state,
);

// Reducer
export const reducers = {
  storeCredentials: (draft, actions) => {
    draft.passkey = actions.payload.passkey;
    draft.userid = actions.payload.userid;
  },
};

// effects
export const effects = {
  // login
  resetPassword: createAsyncThunk(
    actionType.SUBMIT_NEW_PASSWORD,
    async ({ passkey, userid, newpass, confirmpass }) => {
      try {
        const response = await authAPI.resetPassword(
          userid,
          passkey,
          newpass,
          confirmpass,
        );

        if (!response.data) {
          throw new Error('Error when changing password, no data recevied');
        }

        const { message, error, data } = response.data;

        if (error) {
          throw new Error(error);
        }

        return { message, data };
      } catch (error) {
        return Promise.reject(error);
      }
    },
  ),
};

// Extra / Async Reducer
const extraReducers = {
  [effects.resetPassword.pending]: (state) => {
    state.isLoading = true;
    state.isSuccess = false;
  },

  [effects.resetPassword.fulfilled]: (state, action) => {
    state.message = action.payload.message;
    state.isLoading = false;
    state.isSuccess = true;
  },

  [effects.resetPassword.rejected]: (state, action) => {
    state.isLoading = false;
    state.isSuccess = false;
    state.error = action.error;
  },
};

// Slice
const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers,
  extraReducers,
});

export default resetPasswordSlice;
